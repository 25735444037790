<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Pievienot dokumentu"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-col cols="12" class="text-center" v-if="!users && !options">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Dokuments tika veiksmīgi pievienots!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <p v-for="(e, index) in errors" :key="index"> {{ e[0] }} </p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-form @submit="onSubmit" v-if="users && options" enctype="multipart/form-data">
            <b-form-group
                id="input-group-1"
                label="Darbinieka vārds, uzvārds:"
                label-for="input-1"
            >
              <b-form-input id="input-1"
                            list="user-list" @input="changeName"
                            v-model="$v.form.userName.$model"
                            :state="validateState('userName')"
                            aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                  id="input-1-live-feedback"
              >Nepareizi ievadīts darbinieka vārds un uzvārds</b-form-invalid-feedback>

              <datalist id="user-list">
                <option v-for="user in users" :id="user.id" :selected="selectedUser === user.id">{{ user.name }} {{ user.surname }}</option>
              </datalist>
            </b-form-group>

            <b-form-group
                id="input-group-2"
                label="Darbinieka pers. kods:"
                label-for="input-2"
            >
              <b-form-input id="input-2"
                            list="user-data-list" @input="changeCode"
                            v-model="$v.form.userCode.$model"
                            :state="validateState('userCode')"
                            aria-describedby="input-2-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                  id="input-2-live-feedback"
              >Nepareizi ievadīts darbinieka pers. kods</b-form-invalid-feedback>

              <datalist id="user-data-list">
                <option v-for="user in users" :id="user.id">{{ user.personal_code }}</option>
              </datalist>
            </b-form-group>

            <b-form-group
                id="input-group-3"
                label="Dokumenta tips:"
                label-for="input-3"
            >

              <b-form-input id="input-3"
                            list="documenttype"
                            v-model="$v.form.documentType.$model"
                            :state="validateState('documentType')"
                            aria-describedby="input-3-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                  id="input-3-live-feedback"
              >Nepareizi ievadīts dokumenta tips</b-form-invalid-feedback>

              <datalist id="documenttype">
                <option v-for="et in options.document_types.data" :id="et">{{ et.text }}</option>
              </datalist>

            </b-form-group>

            <b-form-group
                id="input-group-4"
                label="Izglītība:"
                label-for="input-4"
            >

              <b-form-input list="educationtype" v-model="form.educationType"></b-form-input>

              <datalist id="educationtype">
                <option v-for="et in options.education_types.data" :id="et">{{ et.text }}</option>
              </datalist>

            </b-form-group>

            <b-form-group
                id="input-group-5"
                label="Izglītības dokumenta izdošanas datums:"
                label-for="input-5"
            >

              <b-form-input id="input-5"
                            type="date"
                            v-model="$v.form.issueDate.$model"
                            :state="validateState('issueDate')"
                            aria-describedby="input-5-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                  id="input-5-live-feedback"
              >Nepareizi ievadīta izglītības dokumenta izdošanas datums</b-form-invalid-feedback>

            </b-form-group>

            <b-form-group
                id="input-group-6"
                label="Derīgs līdz:"
                label-for="input-6"
            >

              <b-form-input type="date" v-model="form.validDate"></b-form-input>

            </b-form-group>

            <b-form-group
                id="input-group-7"
                label="Mācību iestādes nosaukums:"
                label-for="input-7"
            >

              <b-form-input id="input-6"
                            list="educationFacility"
                            v-model="$v.form.educationFacility.$model"
                            :state="validateState('educationFacility')"
                            aria-describedby="input-7-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                  id="input-7-live-feedback"
              >Nepareizi ievadīts mācību iestādes nosaukums</b-form-invalid-feedback>

              <datalist id="educationFacility">
                <option v-for="et in options.education_facility.data" :id="et">{{ et.text }}</option>
              </datalist>

            </b-form-group>

            <b-form-group
                id="input-group-8"
                label="Fakultāte:"
                label-for="input-8"
            >

              <b-form-input list="faculty" v-model="form.faculty"></b-form-input>

              <datalist id="faculty">
                <option v-for="et in options.faculty.data" :id="et">{{ et.text }}</option>
              </datalist>

            </b-form-group>

            <b-form-group
                id="input-group-9"
                label="Programma:"
                label-for="input-9"
            >

              <b-form-input list="program" v-model="form.program"></b-form-input>

              <datalist id="program">
                <option v-for="et in options.programs.data" :id="et">{{ et.text }}</option>
              </datalist>

            </b-form-group>

            <b-form-group
                id="input-group-10"
                label="Specialitāte:"
                label-for="input-10"
            >

              <b-form-input list="speciality" v-model="form.specialty"></b-form-input>

              <datalist id="speciality">
                <option v-for="et in options.specialities.data" :id="et">{{ et.text }}</option>
              </datalist>

            </b-form-group>

            <b-form-group
                id="input-group-11"
                label="Kvalifikācija:"
                label-for="input-11"
            >

              <b-form-input list="qual" v-model="form.qualification"></b-form-input>

              <datalist id="qual">
                <option v-for="et in options.quals.data" :id="et">{{ et.text }}</option>
              </datalist>

            </b-form-group>

            <b-form-group
                id="input-group-11"
                label="Kursi:"
                label-for="input-11"
            >

              <b-form-input list="courses" v-model="form.courses"></b-form-input>

              <datalist id="courses">
                <option v-for="et in options.courses.data" :id="et">{{ et.text }}</option>
              </datalist>

            </b-form-group>

            <b-form-group
                id="input-group-11"
                label="Tēma:"
                label-for="input-11"
            >

              <b-form-input list="theme" v-model="form.theme"></b-form-input>

              <datalist id="theme">
                <option v-for="et in options.themes.data" :id="et">{{ et.text }}</option>
              </datalist>

            </b-form-group>

            <b-form-group
                id="input-group-14"
                label="Veids:"
                label-for="input-14"
            >

              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <b-form-checkbox v-model="form.hours[0]" value="1">Audzināšanas stundas</b-form-checkbox>
                </b-input-group-prepend>
                <b-form-input v-if="form.hours[0]" v-model="form.upbringing" aria-label="Text input with checkbox"></b-form-input>
              </b-input-group>

              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <b-form-checkbox v-model="form.hours[1]" value="1">Bērnu tiesības</b-form-checkbox>
                </b-input-group-prepend>
                <b-form-input v-if="form.hours[1]" v-model="form.children_rights" aria-label="Text input with checkbox"></b-form-input>
              </b-input-group>

              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <b-form-checkbox v-model="form.hours[2]" value="1">Kursi</b-form-checkbox>
                </b-input-group-prepend>
                <b-form-input v-if="form.hours[2]" v-model="form.additional_courses" aria-label="Text input with checkbox"></b-form-input>
              </b-input-group>

            </b-form-group>

            <b-form-group
                id="input-group-15"
                label="Pielikums:"
                label-for="input-15"
            >

              <b-form-file
                  v-model="form.attachment"
                  placeholder="Izvēlieties failu vai ievelciet to šeit..."
                  drop-placeholder="Ievelciet failu šeit..."
                  @change="onFileChange"
              ></b-form-file>

            </b-form-group>

            <b-button type="submit" variant="success">Saglabāt</b-button>
            <b-button variant="warning" :to="{ name: 'DocumentList' }">Atpakaļ</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import jsonToFormData from '@ajoelp/json-to-formdata';
import {mapActions, mapGetters} from "vuex";
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: "Create",
  data() {
    return {
      users: null,
      selectedUser: null,
      created: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      form: {
        userName: '',
        userCode: '',
        user: null,
        documentType: '',
        educationType: '',
        issueDate: '',
        validDate: '',
        educationFacility: '',
        faculty: '',
        program: '',
        specialty: '',
        qualification: '',
        courses: '',
        theme: '',
        hourCount: '',
        attachment: null,
        hours: [0, 0, 0],
        upbringing: null,
        children_rights: null,
        additional_courses: null,
      },
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      userName: {
        required,
      },
      userCode: {
        required,
      },
      documentType: {
        required,
      },
      issueDate: {
        required,
      },
      educationFacility: {
        required,
      },
    },
  },

  computed: {
    ...mapGetters ({
      options: "Options/options",
    })
  },
  methods: {
    ...mapActions ({
      fetchOptions: "Options/fetchOptions"
    }),
    onSubmit(event) {
      event.preventDefault()

      this.errors = [];

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.errors.push('Nav aizpildīti visi lauki')
        window.scrollTo(0, 0);
        return;
      }

      axios.post('/documents', jsonToFormData(this.form)).then(response => {

        this.created = 'success';

        this.form.userCode = '';
        this.form.userName = '';
        this.form.user = null
        this.form.documentType = ''
        this.form.educationType = ''
        this.form.issueDate = ''
        this.form.validDate = ''
        this.form.educationFacility = ''
        this.form.faculty = ''
        this.form.program = ''
        this.form.specialty = ''
        this.form.qualification = ''
        this.form.courses = ''
        this.form.theme = ''
        this.form.hourCount = ''
        this.form.attachment = null
        this.form.hours = [0, 0, 0]
        this.form.upbringing = null
        this.form.children_rights = null
        this.form.additional_courses = null

      }). catch(e => {
        this.created = 'error';
        this.errors = e.response.data.errors
      })

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      this.dismissCountDown = 5;
    },
    changeName(value) {
      let user = (this.users).find((e) => e.name+" "+e.surname === value)
      if(user !== undefined) {
        this.selectedUser = user
        this.form.userCode = user.personal_code
        this.form.user = user.id
      } else {
        this.form.user = null
        this.form.userCode = ''
      }
    },
    changeCode(value) {
      let user = (this.users).find((e) => e.personal_code === value)
      if(user !== undefined) {
        this.selectedUser = user
        this.form.userName = user.name + " " + user.surname
        this.form.user = user.id
      } else {
        this.form.user = null
        this.form.userCode = ''
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    onFileChange(event) {
      this.form.attachment = event.target.files[0]
    },
    validateState(name) {
      // console.log(name)

      const {$dirty, $error} = this.$v.form[name];

      return $dirty ? !$error : null;
    },
  },
  mounted() {
    axios.get('/users').then(response => {
      this.users = response.data.data
    })
    this.fetchOptions();
  },
}
</script>

<style lang="scss" scoped>

</style>